import { Space, Tree, Divider, Typography, InputNumber, Tooltip } from "antd";
import { useEffect, useState } from "react";
import equal from "fast-deep-equal";
const { Text } = Typography;

const TreeField = ({record, insuranceType, onSetCommissionValue}) => {
  const [editCommissonPercentage, setEditCommissonPercentage] = useState(false)
  const [editCommissonTPPercentage, setEditCommissonTPPercentage] = useState(false)
  const [editCommissonODPercentage, setEditCommissonODPercentage] = useState(false)
  const [editRewardPercentage, setEditRewardPercentage] = useState(false)

  const setValue = (e, property, setter) => {
    onSetCommissionValue(property, e.target.value.replace('%', ''), record.key)
    setter(false)
  }

  const generateMotorFields = () => {
    return (
      <>
        <Text type="secondary" onClick={() => setEditCommissonODPercentage(true)}>OD Commission:&nbsp;</Text>
        { !editCommissonODPercentage ?
            <Text onClick={() => setEditCommissonODPercentage(true)}>{record.commissionODPercentage}%</Text> 
          :
            <InputNumber 
              size={'small'} 
              onBlur={(e) => setValue(e, 'commissionODPercentage', setEditCommissonODPercentage)} 
              autoFocus 
              value={record.commissionODPercentage}
              min={0}
              formatter={(commissionTPPercentage) =>
                `${commissionTPPercentage}%`
              }
              parser={(commissionTPPercentage) =>
                commissionTPPercentage.replace("%", "")
              }
            />
        }
        <Divider type="vertical" />
        <Text type="secondary" onClick={() => setEditCommissonTPPercentage(true)}>TP Commission:&nbsp;</Text>
        { !editCommissonTPPercentage ?
            <Text onClick={() => setEditCommissonTPPercentage(true)}>{record.commissionTPPercentage}%</Text> 
          :
            <InputNumber size={'small'} 
              onBlur={(e) => setValue(e, 'commissionTPPercentage', setEditCommissonTPPercentage)} 
              autoFocus 
              value={record.commissionTPPercentage} 
              min={0}
              formatter={(commissionTPPercentage) =>
                `${commissionTPPercentage}%`
              }
              parser={(commissionTPPercentage) =>
                commissionTPPercentage.replace("%", "")
              }
            />
        }
      </>
    )
  }

  const generateNonMotorFields = () => {
    return (
      <>
        <Text type="secondary" onClick={() => setEditCommissonPercentage(true)}>Commission:&nbsp;</Text>
        { !editCommissonPercentage ?
            <Text onClick={() => setEditCommissonPercentage(true)}>{record.commissionPercentage}%</Text> 
          :
            <InputNumber 
              size={'small'} 
              onBlur={(e) => setValue(e, 'commissionPercentage', setEditCommissonPercentage)} 
              autoFocus 
              value={record.commissionPercentage}
              min={0}
              formatter={(commissionTPPercentage) =>
                `${commissionTPPercentage}%`
              }
              parser={(commissionTPPercentage) =>
                commissionTPPercentage.replace("%", "")
              }
            />
        }
      </>
    )
  }

  return (
    <Space>
      <Tooltip title={[record.phoneNumber, record.emailId].join(' | ')}>{record.title}</Tooltip>
      <Divider type="vertical" />
      { insuranceType === 'Motor' ? generateMotorFields():generateNonMotorFields() }
      <Divider type="vertical" />
      <Text type="secondary" onClick={() => setEditRewardPercentage(true)}>Reward:&nbsp;</Text>
      { !editRewardPercentage ?
          <Text onClick={() => setEditRewardPercentage(true)}>{record.rewardPercentage}%</Text> 
        :
          <InputNumber 
            size={'small'} 
            onBlur={(e) => setValue(e, 'rewardPercentage', setEditRewardPercentage)} 
            autoFocus
            value={record.rewardPercentage}
            min={0}
            formatter={(commissionTPPercentage) =>
              `${commissionTPPercentage}%`
            }
            parser={(commissionTPPercentage) =>
              commissionTPPercentage.replace("%", "")
            }
          />
      }
    </Space>
  )
}

const TreeInput = ({value, onChange, agentsHierarchy, insuranceType, commissionODPercentage, commissionPercentage, commissionTPPercentage, rewardPercentage, showAgentTree}) => {
  const [treeData, setTreeData] = useState(agentsHierarchy);
  const [flatTree, setFlatTree] = useState(value)

  useEffect(() => {
    if (showAgentTree) {
      console.log("hi1")
      setTreeData(agentsHierarchy)
    } else {
      let temp = JSON.parse(JSON.stringify(treeData))
      setDefaultValuesInTreeData(temp, commissionODPercentage, commissionPercentage, commissionTPPercentage, rewardPercentage)
      console.log("hi2")
      setTreeData(temp)
    }
  }, [showAgentTree])

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(treeData))
    setValuesInTreeData(temp)
    if(!equal(temp, treeData)) {
      setTreeData(temp)
    }
  }, [flatTree, value])

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(treeData))
    let x = []
    flatenTree(temp, x)
    if (!equal(x, flatTree)) {
      setFlatTree(x)
      onChange?.(x)
    }
  }, [treeData])

  const flatenTree = (rootArray, list) => {
    for(let item of rootArray) {
      if (item.children && item.children.length > 0) {
        flatenTree(item.children, list)
      }
      delete item.children
      list.push(item)
    }
  }

  const setValuesInTreeData = (rootArray) => {
    for(let j in rootArray) {
      let x = value?.find(i => i.key === rootArray[j].key)
      if (x) {
        rootArray[j] = {...rootArray[j], ...x}
      } 
      if (rootArray[j].children && rootArray[j].children.length > 0) {
        setValuesInTreeData(rootArray[j].children)
      }
    }
  }

  const setDefaultValuesInTreeData = (rootArray, commissionODPercentage, commissionPercentage, commissionTPPercentage, rewardPercentage) => {
    for(let item of rootArray) {
      item.commissionODPercentage = commissionODPercentage
      item.commissionPercentage = commissionPercentage
      item.commissionTPPercentage = commissionTPPercentage
      item.rewardPercentage = rewardPercentage
      if (item.children && item.children.length > 0) {
        setDefaultValuesInTreeData(item.children, commissionODPercentage, commissionPercentage, commissionTPPercentage, rewardPercentage)
      }
    }
  }

  const onSetCommissionValue = (key, value, id) => {
    let temp = JSON.parse(JSON.stringify(treeData))
    setRateInTreeData(temp, key, value, id, false)
    setTreeData(temp)
  }

  const setRateInTreeData = (rootArray, key, value, id, found) => {
    for(let item of rootArray) {
      if(found) {
        item[key] = value
        if (item.children && item.children.length > 0) {
          setRateInTreeData(item.children, key, value, id, true)
        }
      } else {
        if (item.key === id) {
          item[key] = value
          if (item.children && item.children.length > 0) {
            setRateInTreeData(item.children, key, value, id, true)
          }
        } else {
          if (item.children && item.children.length > 0) {
            setRateInTreeData(item.children, key, value, id, false)
          }
        }
      }
    }
  }

  return (
    <Tree
      treeData={treeData}
      titleRender={(x) => <TreeField record={x} insuranceType={insuranceType} onSetCommissionValue={onSetCommissionValue} />}
    />
  )
};



export default TreeInput;
