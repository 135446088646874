import { notification } from "antd";
import React, { useState } from "react";
import Comissions from "./ComissionsTable";
import Create from "./Create";

const Rules = (props) => {
  const [showVisibleState, setShowVisibleState] = useState({
    showType: "comissions",
    type: "",
    showData: {},
  });
  const [api, contextHolder] = notification.useNotification();

  return (
    <div>
      {showVisibleState.showType === "comissions" && (
        <Comissions
          user={props.user}
          ruleType={props.ruleType}
          setShowVisibleState={setShowVisibleState}
        />
      )}
      {showVisibleState.showType === "create" && (
        <Create
          user={props.user}
          ruleType={props.ruleType}
          setShowVisibleState={setShowVisibleState}
          api={api}
          type={showVisibleState.type}
          recordId={showVisibleState.showData.id}
        />
      )}
      {contextHolder}
    </div>
  );
};
export default Rules;
