import { Select } from "antd";

const SearchSelect = (props) => {
  return (
    <Select
      mode={props.type === "multiselect" ? "multiple" : null}
      id={props.fieldData.id}
      defaultValue={props?.value}
      showSearch
      placeholder={props.fieldData.placeholder}
      optionFilterProp="children"
      onChange={(value) => props.handleOnChange(value)}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={props.fieldData.values}
    />
  );
};

export default SearchSelect;
