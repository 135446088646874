import Compute from "./components/Compute";
import Reconciliation from "./components/Reconciliation";
import Rules from "./components/Rules";
import { Layout, Menu, Typography, Space, Result, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Agents from "./components/Agents";
import { ReconciliationOutlined, LoginOutlined, LogoutOutlined, UsergroupAddOutlined } from "@ant-design/icons";

const { Title} = Typography;

const { Header, Content, Footer, Sider } = Layout;

const menuItems = [
  {
    key: "PAY_IN",
    label: "Pay In",
    icon: <LoginOutlined />,
    children: [
      { key: "PAYIN_CONFIGURE", label: "Rules" },
      { key: "PAYIN_COMPUTE", label: "Compute" },
    ],
  },
  {
    key: "PAY_OUT",
    label: "Pay Out",
    icon: <LogoutOutlined />,
    children: [
      { key: "PAYOUT_CONFIGURE", label: "Rules" },
      { key: "PAYOUT_COMPUTE", label: "Compute" },
    ],
  },
  {
    key: "RECONCILIATION",
    label: "Reconciliation",
    icon: <ReconciliationOutlined />,
  },
  {
    key: "AGENTS",
    label: "Agents",
    icon: <UsergroupAddOutlined />,
  },
];

const App = () => {
  let queryParams = new URLSearchParams(window.location.search);
  const auth = queryParams.get("auth");

  const [selectedPage, setSelectedPage] = useState("PAYIN_CONFIGURE");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  console.log("user === ", user);
  useEffect(() => {
    if (auth !== null && user === null && loading === false) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/commissions/v1/auth", {
          headers: { Authorization: "Bearer " + auth },
        })
        .then((res) => {
          setUser(res.data);
          setAuthorized(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      window.history.replaceState({}, document.title, "/");
      setLoading(true);
    }
  }, [auth, user, loading]);

  return user !== null ? (
    <Layout>
      <Header className="header" theme="dark">
        <Title level={2} style={{color: 'white', marginTop: '12px'}}>Commissions</Title>
      </Header>
      <Layout>
        <Sider
          theme="dark"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}>
          {/* <div className="logo" /> */}
          <Menu
            theme="dark"
            mode="inline"
            defaultOpenKeys={["PAY_IN"]}
            selectedKeys={[selectedPage]}
            onClick={(e) => setSelectedPage(e.key)}
            items={menuItems}
          />
        </Sider>
        <Layout>
          <Content
            style={{
              margin: "16px 16px 0",
            }}>
            <div
              className="site-layout-background"
              style={{
                // padding: 24,
                minHeight: "83vh",
                background: "white",
              }}>
              {selectedPage === "PAYIN_CONFIGURE" ? (
                <Rules user={user} ruleType={"PAY_IN"} />
              ) : selectedPage === "PAYOUT_CONFIGURE" ? (
                <Rules user={user} ruleType={"PAY_OUT"} />
              ) : selectedPage === "RECONCILIATION" ? (
                <Reconciliation user={user} />
              ) : selectedPage === "PAYIN_COMPUTE" ? (
                <Compute user={user} ruleType={"PAY_IN"} />
              ) : selectedPage === "PAYOUT_COMPUTE" ? (
                <Compute user={user} ruleType={"PAY_OUT"} />
              ) : selectedPage === "AGENTS" ? (
                <Agents user={user} />
              ) : (
                <Compute user={user} />
              )}
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}>
            Ensuredit © 2022 Created by Ensuredit
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  ) : loading ? (
    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
      <Space direction="vertical" style={{ height: '100vh', justifyContent: 'center' }}>
        <Spin size="large" />
      </Space>
    </Space>
  ) : !authorized ? (
    <Result
      status="403"
      title="401"
      subTitle="Sorry, you are not authorized to access this page. Please come back from ICE App."
    />
  ) : null;
};

export default App;
