import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import React from "react";

const { Title } = Typography;

const { Dragger } = Upload;

const Reconciliation = (props) => {
  const { user } = props;
  const [form] = Form.useForm();

  const uploadCSV = async (options) => {
    const { onSuccess, onError, file } = options;
    try {
      const formData = new FormData();
      formData.append("file", file);

      onSuccess("Ok");
    } catch (err) {
      onError(err);
    }
  };

  const populateUploadField = (label, name, showTooltip) => {
    let uploadProps = {
      name: "file",
      accept: ".csv",
      multiple: false,
      maxCount: 1,
      customRequest: uploadCSV,
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          form.setFieldsValue({
            [name]: info.fileList.length === 0 && null,
          });
        }
        if (status === "done") {
          form.setFieldsValue({
            [name]: info.fileList.length > 0 ? info.fileList[0] : null,
          });
        }
      },
    };
    return (
      <Form.Item
        label={label}
        name={name}
        tooltip={
          showTooltip && {
            title: (
              <div>
                For sample template file please check the sample
                <a
                  onClick={() => {
                    axios
                      .get(
                        process.env.REACT_APP_BACKEND_URL +
                          "/commissions/v1/reconcile",
                        {
                          headers: { Authorization: "Bearer " + user.token },
                        }
                      )
                      .then((res) => {
                        const href = URL.createObjectURL(
                          new Blob([res.data], { type: "csv" })
                        );
                        const link = document.createElement("a");
                        link.href = href;
                        let today = new Date();
                        const dd = String(today.getDate()).padStart(2, "0");
                        const mm = String(today.getMonth() + 1).padStart(
                          2,
                          "0"
                        ); //January is 0!
                        const yyyy = today.getFullYear();
                        today = dd + "-" + mm + "-" + yyyy;
                        link.setAttribute(
                          "download",
                          `sample_template_${today}.csv`
                        ); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}>
                  {" "}
                  Reconciliation Template
                </a>
                .
              </div>
            ),
            icon: <InfoCircleOutlined />,
          }
        }
        rules={[
          { required: true, message: "Please select a CSV file to upload" },
        ]}>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Only csv file is supported.</p>
        </Dragger>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    const form = new FormData();
    form.append("insurerFile", values.insurerFile.originFileObj);
    form.append("partnerFile", values.partnerFile.originFileObj);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/commissions/v1/reconcile",
        form,
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute(
          "download",
          `reconciliation_output_${Date.now()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        message.error(err?.data?.message || err?.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col offset={2} xs={18} sm={18} md={20} lg={20} xl={20}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Title>Commission Reconciliation</Title>
          </Space>
          <Divider />
          <Form
            labelCol={{ span: 24 }}
            form={form}
            name="basic"
            wrapperCol={{ span: 14 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Row>
              <Col xs={23} sm={24} md={24} lg={24} xl={24}>
                {populateUploadField("Insurer File", "insurerFile", true)}
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {populateUploadField("Partner File", "partnerFile", false)}
              </Col>
            </Row>
            <Row>
              <Col
                css={{
                  justifyContent: "end",
                  display: "flex",
                }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </Col>
    </Row>
  );
};
export default Reconciliation;
