/** @jsxImportSource @emotion/react */

import {
  ArrowLeftOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { AntDValueEditor, QueryBuilderAntD } from "@react-querybuilder/antd";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch
} from "antd";
import TreeInput from "./TreeInput";
import Title from "antd/es/typography/Title";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import SearchSelect from "./SearchSelect";

dayjs.extend(customParseFormat);

const QueryBuilderComponent = ({
  form,
  user,
  product,
  insurer,
  defaultQuery,
  disabled,
}) => {
  const [query, setQuery] = useState({
    combinator: "and",
    rules: [],
  });

  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/commissions/v1/properties?product=${product}&insurer=${insurer}`,
        {
          headers: { Authorization: "Bearer " + user.token },
        }
      )
      .then((res) => {
        const responseFields = res?.data?.data.map(
          ({ id, fieldName, operators, options, fieldType }) => {
            let obj = {};

            if (fieldType === "integer") {
              obj.inputType = "number";
            } else if (fieldType === "lookup" || fieldType === "dropdown") {
              obj = {
                datatype: fieldType,
                values: options?.map((value) => {
                  return {
                    value: value,
                    label: value,
                  };
                }),
              };
            }

            return {
              id: id,
              name: fieldName,
              label: fieldName,
              placeholder: fieldName,
              operators: operators.map((val) => {
                return { name: val, label: val };
              }),
              ...obj,
            };
          }
        );

        setFields(responseFields);

        setQuery(
          defaultQuery
            ? defaultQuery
            : {
                combinator: "and",
                rules: [],
              }
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [product, insurer, user.token, defaultQuery]);

  useEffect(() => {
    form.setFieldsValue({
      rule: query.rules.length ? query : null,
    });
  }, [form, query]);

  useEffect(() => {
    setQuery((val) => {
      return { ...val, disabled: disabled };
    });
  }, [disabled]);

  return (
    <>
      {loading ? (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Spin size="small" />
        </div>
      ) : (
        <QueryBuilderAntD>
          <QueryBuilder
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            showCombinatorsBetweenRules
            fields={fields}
            query={query}
            onQueryChange={(q) => setQuery(q)}
            controlElements={{
              valueEditor: CustomValueEditor,
            }}
            resetOnOperatorChange
          />
        </QueryBuilderAntD>
      )}
    </>
  );
};

export const CustomValueEditor = (props) => {
  if (
    props.fieldData.datatype === "lookup" ||
    props.fieldData.datatype === "dropdown"
  ) {
    const obj = {};
    if (props.operator === "IN" || props.operator === "NOT IN") {
      obj.type = "multiselect";
    }

    return (
      <SearchSelect
        key={props.fieldData.id + props.operator}
        {...props}
        {...obj}
      />
    );
  }

  return <AntDValueEditor {...props} />;
};

export const dateFormat = "DD-MM-YYYY";

const viewMapper = {
  view: "View",
  edit: "Edit",
};

const Create = (props) => {
  const [form] = Form.useForm();
  const [defaultQuery, setDefaultQuery] = useState(null);
  const [productsInsures, setProductsInsurers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [products, setProducts] = useState([]);
  const [insurerName, setInsurerName] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAgentTree, setShowAgentTree] = useState(true);
  const [agentsHierarchy, setAgentsHierarchy] = useState(null);
  const [commissionODPercentage, setCommissionODPercentage] = useState(0)
  const [commissionPercentage, setCommissionPercentage] = useState(0)
  const [commissionTPPercentage, setCommissionTPPercentage] = useState(0)
  const [rewardPercentage, setRewardPercentage] = useState(0)

  const { type: viewType, recordId, api } = props;

  const [type, setType] = useState(viewType);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (props.ruleType === 'PAY_OUT' && !agentsHierarchy) {
          axios.get(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/hierarchy`, { headers: { Authorization: "Bearer " + props.user.token }})
          .then(res => setAgentsHierarchy(res.data.hierarchy))
        }
        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/commissions/v1/product-insurer-mappings",
          {
            headers: { Authorization: "Bearer " + props.user.token },
          }
        );

        const responseProducts = res.data?.data.map((value) => {
          return value.product;
        });

        setProductsInsurers(res.data.data);
        setProducts(
          responseProducts?.filter(
            (item, index) => responseProducts.indexOf(item) === index
          )
        );

        if (type === "view" || type === "edit") {
          const viewRecordResponse = await axios.get(
            process.env.REACT_APP_BACKEND_URL +
              `/commissions/v1/rules/${recordId}`,
            {
              headers: { Authorization: "Bearer " + props.user.token },
            }
          );

          const {
            product,
            startDate,
            endDate,
            rewardPercentage,
            insurer,
            commissionOdPercentage,
            commissionTpPercentage,
            commissionPercentage,
            agentsCommission,
            rule,
          } = viewRecordResponse?.data || {};

          setSelectedProduct(product);
          setInsurerName(insurer);
          setDefaultQuery({ ...rule, disabled: type === "view" && true });
          setShowAgentTree(!(agentsCommission && agentsCommission.length > 0))

          form.setFieldsValue({
            product,
            startDate: dayjs(startDate, dateFormat),
            endDate: endDate && dayjs(endDate, dateFormat),
            rewardPercentage,
            insurer,
            commissionOdPercentage,
            commissionTpPercentage,
            commissionPercentage,
            agentsCommission
          });

          setDisableForm(type === "view" && true);
        }
      } catch (err) {}
      setLoading(false);
    })();
  }, []);

  const onFinish = (values) => {
    let axiosPromise;
    if (type === "edit") {
      axiosPromise = axios.put(
        process.env.REACT_APP_BACKEND_URL + `/commissions/v1/rules/${recordId}`,
        {
          sales_channel_id: props.user.salesChannelUserId,
          ...values,
          startDate: values.startDate.format(dateFormat),
          ruleType: props.ruleType,
          endDate:
            (values?.endDate && values?.endDate?.format(dateFormat)) || null,
        },
        {
          headers: {
            Authorization: "Bearer " + props.user.token,
          },
        }
      );
    } else {
      axiosPromise = axios.post(
        process.env.REACT_APP_BACKEND_URL + "/commissions/v1/rules",
        {
          sales_channel_id: props.user.salesChannelUserId,
          ...values,
          startDate: values.startDate.format(dateFormat),
          ruleType: props.ruleType,
          endDate:
            (values?.endDate && values?.endDate?.format(dateFormat)) || null,
        },
        {
          headers: {
            Authorization: "Bearer " + props.user.token,
          },
        }
      );
    }
    axiosPromise
      .then((res) => {
        api.success({
          message: "Success",
          description: `Commission data has been ${
            type === "edit" ? "saved" : "submited"
          } succesfully.`,
          placement: "bottomRight",
        });

        if (type !== "edit") {
          props.setShowVisibleState({
            showType: "comissions",
            type: "",
            showData: {},
          });
        }
      })
      .catch((err) => {
        api.error({
          message: "Error",
          description: err?.data?.message || err?.message,
          placement: "bottomRight",
        });
      });
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 24 }}
      initialValues={{
        remember: false,
        commissionOdPercentage: 0,
        rewardPercentage: 0,
        commissionTpPercentage: 0,
        commissionPercentage: 0,
      }}
      onFinish={onFinish}
      autoComplete="off"
      preserve={false}
      form={form}
      disabled={disableForm}>
      {loading && (
        <Spin
          css={{
            display: loading ? "flex" : "none",
            minHeight: "83vh",
            alignItems: "center",
            justifyContent: "center",
          }}
          size="large"
        />
      )}
      <div
        css={{
          display: !loading ? "block" : "none",
        }}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row>
            <Col xs={18} sm={18} md={8} lg={18} xl={16}>
              <Space
                direction="horizontal"
                css={{
                  width: "100%",
                  margin: "2em",
                }}>
                <a
                  onClick={() => {
                    props.setShowVisibleState({
                      showType: "comissions",
                      type: "",
                      showData: {},
                    });
                  }}>
                  <ArrowLeftOutlined
                    css={{
                      marginRight: "6px",
                    }}
                  />
                  Back
                </a>
              </Space>
            </Col>
            <Col offset={2} xs={18} sm={18} md={18} lg={18} xl={18}>
              <div
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}>
                <Title
                  css={{
                    margin: "0px !important",
                  }}>
                  {`${
                    type === "edit" || type === "view"
                      ? viewMapper[type]
                      : "Create"
                  } Commission${
                    type === "edit" || type === "view"
                      ? ` ID - ${recordId}`
                      : " Rule"
                  }`}
                </Title>
                {(type === "edit" || type === "view") && (
                  <Button
                    onClick={() => {
                      setType("edit");
                      setDisableForm(false);
                    }}
                    icon={<EditOutlined />}
                    disabled={!disableForm}
                    type="primary"
                    htmlType="submit">
                    Edit
                  </Button>
                )}
              </div>
              <Divider />
            </Col>
          </Row>

          <Row
            css={{
              ...(type === "view"
                ? {
                    span: {
                      color: "rgba(0, 0, 0, 0.88)",
                    },
                    input: {
                      color: "rgba(0, 0, 0, 0.88) !important",
                    },
                  }
                : {}),
              "& .ruleGroup": {
                background: "none",
              },
            }}>
            <Col offset={2} xs={18} sm={18} md={12} lg={12} xl={8}>
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}>
                <Form.Item
                  label="Product"
                  name="product"
                  rules={[
                    { required: true, message: "Please select a Product" },
                  ]}
                  tooltip={{
                    title: "Please select a type of insurence.",
                    icon: <InfoCircleOutlined />,
                  }}>
                  <Select
                    showSearch
                    value={selectedProduct}
                    placeholder="Select a Product"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setDefaultQuery(null);
                      setSelectedProduct(value);
                      setInsurerName(null);

                      form.setFieldsValue({
                        insurer: null,
                      });
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={products.map((value) => {
                      return {
                        value: value,
                        label: value,
                      };
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Insurer Name"
                  name="insurer"
                  tooltip={{
                    title: "Please select a Insurer for the product.",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select an Insurer Name.",
                    },
                  ]}>
                  <Select
                    showSearch
                    allowClear={true}
                    placeholder="Select an insurer"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val) => {
                      setDefaultQuery(null);
                      setInsurerName(val);
                    }}
                    options={productsInsures
                      ?.filter?.((value) => value.product === selectedProduct)
                      .map((value) => {
                        return {
                          value: value.insurer,
                          label: value.insurer,
                        };
                      })}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col offset={2} xs={18} sm={18} md={12} lg={12} xl={8}>
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}>
                <Space
                  direction="horizontal"
                  size="middle"
                  style={{ display: "flex" }}>
                  <Form.Item
                    tooltip={{
                      title: "Rule is applicable from this date.",
                      icon: <InfoCircleOutlined />,
                    }}
                    label="Start Date"
                    name="startDate"
                    rules={[
                      { required: true, message: "Please select a Start Date" },
                    ]}>
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                  <Form.Item
                    tooltip={{
                      title: "Rule is applicable till this date.",
                      icon: <InfoCircleOutlined />,
                    }}
                    label="End Date"
                    name="endDate">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                </Space>
              </Space>
            </Col>
            <Col offset={2} xs={18} sm={18} md={18} lg={18} xl={18}>
              <Form.Item
                tooltip={{
                  title: "Please define commission rules.",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please specify a set of rules." },
                ]}
                label="Rule"
                name="rule">
                <QueryBuilderComponent
                  form={form}
                  user={props.user}
                  product={selectedProduct}
                  insurer={insurerName}
                  defaultQuery={defaultQuery}
                  disabled={disableForm}
                />
              </Form.Item>
            </Col>
            <Col offset={2} xs={18} sm={18} md={18} lg={18} xl={18}>
              <Row>
              {productsInsures?.filter?.(
                (value) => value.product === selectedProduct
              )?.[0]?.insuranceType === "Motor" ? (
                <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    tooltip={{
                      title: "(OD) Own Damage commission percentage.",
                      icon: <InfoCircleOutlined />,
                    }}
                    label="Commission OD Percentage"
                    name="commissionOdPercentage"
                    rules={[
                      {
                        min: 0,
                        type: "number",
                      },
                      {
                        required: true,
                        message: "Please select a Commission OD Percentage",
                      },
                    ]}>
                    <InputNumber
                      min={0}
                      formatter={(commissionODPercentage) =>
                        `${commissionODPercentage}%`
                      }
                      parser={(commissionODPercentage) =>
                        commissionODPercentage.replace("%", "")
                      }
                      onChange={setCommissionODPercentage}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    tooltip={{
                      title: "(TP) Third Party commission percentage.",
                      icon: <InfoCircleOutlined />,
                    }}
                    label="Commission TP Percentage"
                    name="commissionTpPercentage"
                    rules={[
                      {
                        min: 0,
                        type: "number",
                      },
                      {
                        required: true,
                        message: "Please specify a Commission TP Percentage",
                      },
                    ]}>
                    <InputNumber
                      min={0}
                      formatter={(commissionTPPercentage) =>
                        `${commissionTPPercentage}%`
                      }
                      parser={(commissionTPPercentage) =>
                        commissionTPPercentage.replace("%", "")
                      }
                      onChange={setCommissionTPPercentage}
                    />
                  </Form.Item>
                </Col>
                </>
              ) : (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    tooltip={{
                      title: "Commission percentage.",
                      icon: <InfoCircleOutlined />,
                    }}
                    label="Commission Percentage"
                    name="commissionPercentage"
                    rules={[
                      {
                        min: 0,
                        type: "number",
                      },
                      {
                        required: true,
                        message: "Please specify a Commission Percentage",
                      },
                    ]}>
                    <InputNumber
                      min={0}
                      formatter={(commissionPercentage) =>
                        `${commissionPercentage}%`
                      }
                      parser={(commissionPercentage) =>
                        commissionPercentage.replace("%", "")
                      }
                      onChange={setCommissionPercentage}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  tooltip={{
                    title: "Reward commission percentage.",
                    icon: <InfoCircleOutlined />,
                  }}
                  label="Reward"
                  name="rewardPercentage"
                  rules={[
                    {
                      min: 0,
                      type: "number",
                    },
                    {
                      required: true,
                      message: "Please specify a Reward Percentage",
                    },
                  ]}>
                  <InputNumber
                    min={0}
                    formatter={(commissionTPPercentage) =>
                      `${commissionTPPercentage}%`
                    }
                    parser={(commissionTPPercentage) =>
                      commissionTPPercentage.replace("%", "")
                    }
                    onChange={setRewardPercentage}
                  />
                </Form.Item>
              </Col>
              </Row>
            </Col>
            {props.ruleType === 'PAY_OUT' &&
            <Col offset={2} xs={18} sm={18} md={18} lg={18} xl={18}>
              <span>Commission rate same for all the Agents:&nbsp;&nbsp;</span>
              <Switch checked={showAgentTree} onChange={setShowAgentTree} checkedChildren="Yes" unCheckedChildren="No" />
              {!showAgentTree ?
              <Form.Item
                tooltip={{
                  title: "Configure agent wise payout commission and reward",
                  icon: <InfoCircleOutlined />,
                }}
                label="Agent Wise Commission"
                name="agentsCommission"
              >
                <TreeInput 
                  agentsHierarchy={agentsHierarchy} 
                  insuranceType={productsInsures?.filter?.((value) => value.product === selectedProduct)?.[0]?.insuranceType}
                  commissionODPercentage={commissionODPercentage}
                  commissionTPPercentage={commissionTPPercentage}
                  commissionPercentage={commissionPercentage}
                  rewardPercentage={rewardPercentage}
                  showAgentTree={showAgentTree}
                />
              </Form.Item> : null}
            </Col>}
          </Row>
        </Space>
        {type !== "view" && (
          <Row>
            <Col
              css={{
                justifyContent: "end",
                display: "flex",
              }}
              offset={2}
              xs={18}
              sm={18}
              md={18}
              lg={18}
              xl={18}>
              <Space
                direction="horizontal"
                size="middle"
                style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    onClick={() => {
                      props.setShowVisibleState({
                        showType: "comissions",
                        type: "",
                        showData: {},
                      });
                    }}>
                    Cancel
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {type === "edit" ? "Save" : "Submit"}
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        )}
      </div>
    </Form>
  );
};

export default Create;
