import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  message,
  Progress,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import axios from "axios";
import React, { useState } from "react";

const { Title } = Typography;

const { Dragger } = Upload;

const Compute = (props) => {
  const { user } = props;
  // const [product, setProduct] = useState(null)
  // const [productOptions, setProductOptions] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   axios.get(process.env.REACT_APP_BACKEND_URL + '/api/v1/products', {headers: {Authorization: 'Bearer ' + user.token}})
  //     .then(res => {setProductOptions(res.data.map(row => {return {value: row.id, label: row.displayName, internalName: row.internalName}}))})
  //     .catch(err => console.log(err))
  // }, [])

  // const populateProductField = () => {
  //   return (
  //     <Form.Item
  //       label="Product"
  //       name="product"
  //       rules={[{ required: true, message: 'Please select a Product' }]}
  //     >
  //       <Select
  //         showSearch
  //         value={product}
  //         placeholder="Select a product"
  //         optionFilterProp="children"
  //         onChange={setProduct}
  //         filterOption={(input, option) =>
  //           (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  //         }
  //         options={productOptions}
  //       />
  //     </Form.Item>
  //   )
  // }

  const uploadCSV = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    var formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: { Authorization: "Bearer " + user.token },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
      responseType: "blob",
    };
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/commissions/v1/compute?rule_type=${props.ruleType}`,
        formData,
        config
      )
      .then((res) => {
        if (res.data.type === "application/json") {
          onError("Compute Failed");
          message.error("Uploaded File format is not valid");
        } else {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          today = dd + "-" + mm + "-" + yyyy;
          link.setAttribute("download", `commission_calculation_${today}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          onSuccess("Ok");
        }
      })
      .catch((err) => {
        console.log(err);
        onError(err);
      });
  };

  const populateUploadField = () => {
    let uploadProps = {
      name: "file",
      accept: ".csv",
      multiple: false,
      customRequest: uploadCSV,
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        setUploadedFile(e.dataTransfer.files);
      },
    };
    return (
      <Form.Item
        label="Upload File"
        name="uploadFile"
        tooltip={{
          title: (
            <div>
              Please select the commission template file to upload and compute
              the commission. For sample template file please check the sample
              <a
                onClick={() => {
                  axios
                    .get(
                      process.env.REACT_APP_BACKEND_URL +
                        `/commissions/v1/compute?rule_type=${props.ruleType}`,
                      {
                        headers: { Authorization: "Bearer " + user.token },
                      }
                    )
                    .then((res) => {
                      console.log("response === ", res);
                      const href = URL.createObjectURL(
                        new Blob([res.data], { type: "csv" })
                      );
                      const link = document.createElement("a");
                      link.href = href;
                      let today = new Date();
                      const dd = String(today.getDate()).padStart(2, "0");
                      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                      const yyyy = today.getFullYear();
                      today = dd + "-" + mm + "-" + yyyy;
                      link.setAttribute(
                        "download",
                        `sample_template_${today}.csv`
                      ); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(href);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}>
                {" "}
                Comissions Template
              </a>
              .
            </div>
          ),
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          { required: true, message: "Please select a CSV file to upload" },
        ]}>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Only csv file is supported.</p>
        </Dragger>
        {progress > 0 ? <Progress percent={progress} /> : null}
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col offset={2} xs={18} sm={18} md={20} lg={20} xl={20}>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Title>Compute Commission</Title>
          </Space>
          <Divider />
          <Form
            name="basic"
            // labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            {/* {populateProductField()} */}
            {populateUploadField()}
          </Form>
        </Space>
      </Col>
    </Row>
  );
};
export default Compute;
