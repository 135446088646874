/** @jsxImportSource @emotion/react */
import { dateFormat } from "./Create";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";

const Comissions = (props) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleted, setIsDeleted] = useState(false);

  const [columns] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => text,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text) => text,
    },
    {
      title: "Insurer",
      dataIndex: "insurer",
      key: "insurer",
      render: (text) => text,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => text,
    },
    {
      title: "Commission Percentage",
      dataIndex: "commissionPercentage",
      key: "commissionPercentage",
      render: (text) => `${text}%`,
    },
    {
      title: "OD Percentage",
      dataIndex: "commissionOdPercentage",
      key: "commissionOdPercentage",
      render: (text) => `${text}%`,
    },
    {
      title: "TP Percentage",
      dataIndex: "commissionTpPercentage",
      key: "commissionTpPercentage",
      render: (text) => `${text}%`,
    },
    {
      title: "Reward Percentage",
      dataIndex: "rewardPercentage",
      key: "rewardPercentage",
      render: (text) => `${text}%`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        console.log("record === ", record);
        return (
          <Space size="middle">
            <a
              onClick={() => {
                props.setShowVisibleState({
                  showType: "create",
                  type: "view",
                  showData: {
                    id: record.id,
                  },
                });
              }}>
              View
            </a>
            <a
              onClick={() => {
                props.setShowVisibleState({
                  showType: "create",
                  type: "edit",
                  showData: {
                    id: record.id,
                  },
                });
              }}>
              Edit
            </a>
            <Popconfirm
              title="Delete Rule"
              description="Are you sure you want to delete this rule?"
              onConfirm={async () => {
                try {
                  await axios.delete(
                    process.env.REACT_APP_BACKEND_URL +
                      `/commissions/v1/rules/${record.id}`,
                    {
                      headers: {
                        Authorization: "Bearer " + props.user.token,
                      },
                    }
                  );

                  setIsDeleted((val) => !val);
                } catch (err) {}
              }}
              okText="Yes"
              cancelText="No">
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [productsInsures, setProductsInsurers] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [insurerName, setInsurerName] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const productInsurerResponse = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/commissions/v1/product-insurer-mappings",
          {
            headers: { Authorization: "Bearer " + props.user.token },
          }
        );

        const responseProducts = productInsurerResponse.data?.data.map(
          (value) => {
            return value.product;
          }
        );

        setProductsInsurers(productInsurerResponse.data.data);
        setProducts(
          responseProducts?.filter(
            (item, index) => responseProducts.indexOf(item) === index
          )
        );
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        let query = "";

        if (selectedProduct) {
          query = `&product=${selectedProduct}`;
        }
        if (insurerName) {
          query += `&insurer=${insurerName}`;
        }
        if (fromDate) {
          query += `&from_date=${fromDate?.format(dateFormat)}`;
        }
        if (toDate) {
          query += `&to_date=${toDate?.format(dateFormat)}`;
        }
        query += `&rule_type=${props.ruleType}`;

        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL +
            `/commissions/v1/rules?page_number=${pageNumber}${query}`,
          {
            headers: { Authorization: "Bearer " + props.user.token },
          }
        );

        setTableData(
          res?.data?.data.map((elem) => {
            return { ...elem, key: elem.id };
          })
        );
        setCount(res?.data?.count);
      } catch (err) {}
      setLoading(false);
    })();
  }, [
    props.user.token,
    isDeleted,
    pageNumber,
    selectedProduct,
    insurerName,
    toDate,
    fromDate,
    props.ruleType,
  ]);

  return (
    <Row>
      <Col offset={2} xs={18} sm={18} md={20} lg={20} xl={20}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Title>Commissions</Title>
          </Space>

          <Divider />

          <Row justify="space-between">
            <Space
              direction="horizontal"
              size="small"
              style={{ display: "flex", flexWrap: "wrap" }}>
              <Select
                showSearch
                allowClear
                value={selectedProduct}
                placeholder="Select a Product"
                optionFilterProp="children"
                onChange={(value) => {
                  setSelectedProduct(value || null);
                  setInsurerName(null);
                  setPageNumber(1);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={products.map((value) => {
                  return {
                    value: value,
                    label: value,
                  };
                })}
              />
              <Select
                showSearch
                allowClear
                value={insurerName}
                placeholder="Select an insurer"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(val) => {
                  setInsurerName(val || null);
                  setPageNumber(1);
                }}
                options={productsInsures
                  ?.filter?.((value) => value.product === selectedProduct)
                  .map((value) => {
                    return {
                      value: value.insurer,
                      label: value.insurer,
                    };
                  })}
              />

              <DatePicker
                placeholder="From Date"
                format={dateFormat}
                value={fromDate}
                onChange={(val) => {
                  setFromDate(val);
                  setPageNumber(1);
                }}
              />

              <DatePicker
                placeholder="To Date"
                format={dateFormat}
                value={toDate}
                onChange={(val) => {
                  setToDate(val);
                  setPageNumber(1);
                }}
              />
            </Space>
            <Button
              type="primary"
              htmlType="submit"
              css={{
                float: "right",
              }}
              icon={<PlusOutlined />}
              onClick={() => {
                props.setShowVisibleState({
                  showType: "create",
                  type: "",
                  showData: {},
                });
              }}>
              Create New Commission Rule
            </Button>
          </Row>

          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            loading={loading}
            pagination={{
              current: pageNumber,
              total: count,
              pageSize: 20,
              onChange: (e) => {
                setPageNumber(e);
              },
              showSizeChanger: false,
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};
export default Comissions;
