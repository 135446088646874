import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Form, Row, Col, Space, Divider, Typography, TreeSelect, Popconfirm, Drawer, Tree, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';

const { Title } = Typography


const Agents = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editingKey, setEditingKey] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [agentsHierarchy, setAgentsHierarchy] = useState([]);
  const [form] = useForm();

  useEffect(() => {
    setEditingKey(null)
    getAgentsData(pageNumber)
    getAgentsHierarchy()
  }, []);

  const getAgentsData = (pageNumber) => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents?page_number=${pageNumber}`, { headers: { Authorization: "Bearer " + props.user.token }})
      .then(response => {
        setTableData(response.data.data)
        setCount(response.data.count);
        setPageNumber(pageNumber)
      })
  }

  const getAgentsHierarchy = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/hierarchy`, { headers: { Authorization: "Bearer " + props.user.token }})
      .then(response => {
        setAgentsHierarchy(response.data.hierarchy)
      })
  }

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(null);
    setTableData(tableData.filter((item) => item.id !== 'DEFAULT'))
    form.resetFields()
  };

  const save = (record) => {
    let newData = {...record, ...form.getFieldsValue()}
    if (newData.id === 'DEFAULT') {
      delete newData.id
      axios.post(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents`, newData, {headers: { Authorization: "Bearer " + props.user.token }})
      .then(res => {
        getAgentsData(pageNumber)
        getAgentsHierarchy()
        setEditingKey(null)
        form.resetFields()
      });
    } else {
      axios.put(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/${newData.id}`, newData, {headers: { Authorization: "Bearer " + props.user.token }})
      .then(res => {
        getAgentsData(pageNumber)
        getAgentsHierarchy()
        setEditingKey(null)
        form.resetFields()
      });
    }
  };

  const deleteRow = (record) => {
    axios.delete(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/${record.id}`, {headers: { Authorization: "Bearer " + props.user.token }})
      .then(res => {
        setTableData(tableData.filter((item) => item.id !== record.id));
        getAgentsData(pageNumber)
        getAgentsHierarchy()
      });
    form.resetFields()
  };

  const findNameFromId = (rootArray, id) => {
    let title;
    for(let item of rootArray) {
      if(item.key === id) {
        return item.title;
      }
      if(item.children && item.children.length > 0) {
        title = title || findNameFromId(item.children, id)
      }
    }
    return title
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '20%',
    },
    {
      title: 'Email ID',
      dataIndex: 'emailId',
      key: 'emailId',
      editable: true,
      width: '20%',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      editable: true,
      width: '20%',
    },
    {
      title: 'Reports To',
      dataIndex: 'reportsToId',
      key: 'reportsToId',
      render: (_, record) => <span>{findNameFromId(agentsHierarchy, record.reportsToId)}</span>,
      editable: true,
      width: '20%',
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="link" onClick={(e) => save(record)}>
              Save
            </Button>
            <Button type="link" onClick={cancel}>
              Cancel
            </Button>
          </span>
        ) : (
          <span>
            <Button type="link" onClick={() => edit(record)}>
              Edit
            </Button>
            <Popconfirm title="Delete Agent" description="Are you sure you want to delete this agent?" onConfirm={() => deleteRow(record)} okText="Yes" cancelText="No">
              <a>Delete</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        agentsHierarchy: agentsHierarchy
      }),
    };
  });

  const addNewAgent = () => {
    if (!tableData.find(item => item.id === 'DEFAULT')) {
      setTableData([{id: 'DEFAULT', name: null, phoneNumber: null, reportsToId: null, emailId: null}, ...tableData])
      setEditingKey("DEFAULT")
    }
  }

  const uploadFile = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    var formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: { Authorization: "Bearer " + props.user.token },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    axios.post(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/upload`, formData,config)
      .then(res => {
        onSuccess("Ok");
        getAgentsData(pageNumber)
        getAgentsHierarchy()
      })
      .catch(err => {
        onError(err)
      })
  }

  const downloadAgentUploadTemplate = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + `/commissions/v1/agents/template`,{ headers: { Authorization: "Bearer " + props?.user.token }})
    .then((res) => {
      const href = URL.createObjectURL(
        new Blob([res.data], { type: "csv" })
      );
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download",`sample_agents_template.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <Row>
      <Col offset={2} xs={18} sm={18} md={20} lg={20} xl={20}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Space direction="horizontal" style={{ width: "100%" }}>
            <Title>Agents</Title>
          </Space>
          <Divider />
        <Row justify="space-between">
          <Space direction='horizontal'>
            <Button type="primary" ghost onClick={() => setOpenDrawer(true)}>Show Hierachy</Button>
          </Space>
          <Space direction="horizontal">
            <Space.Compact>
              <Upload accept="text/csv" customRequest={uploadFile}>
                <Button>Upload Agents CSV</Button>
              </Upload>
              <Button icon={<DownloadOutlined/>} onClick={downloadAgentUploadTemplate}/>
            </Space.Compact>
            
            <Button type="primary" onClick={addNewAgent}>Add New Agent</Button>
          </Space>
        </Row>
        <Drawer
          title={"Agents Hierarchy"}
          placement="right"
          size={"default"}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
        >
          <Tree treeData={agentsHierarchy} defaultExpandAll/>
        </Drawer>
        <Form form={form} component={false}>
          <Table
            dataSource={[...tableData]}
            columns={mergedColumns}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            pagination={{
              current: pageNumber,
              total: count,
              pageSize: 20,
              onChange: getAgentsData,
            }}
          />
        </Form>
        </Space>
      </Col>
    </Row>
  );
};

const EditableCell = ({ editing, dataIndex, title, record, index, children, agentsHierarchy, ...restProps }) => {

  const inputNode = dataIndex === 'reportsToId' ? 
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      allowClear
      treeDefaultExpandAll
      fieldNames={{label : 'title', value: 'key'}}
      treeData={agentsHierarchy}
    /> 
    : 
    <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default Agents;
